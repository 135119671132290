/*!
  * Principal Design System
  * Component: select
  * Version: 4.4.0
  * Build Date: 7/6/2021, 3:55:10 PM
  * Repo: https://scm.principal.com/projects/GCBIDESIGN/repos/principal-design-system/browse
  * Cormorant Infant Font licensing: https://www.principalcdn.com/css/principal-design-system/pds/latest/fonts/cormorant-infant/OFL.txt
  * Work Sans Font licensing: https://www.principalcdn.com/css/principal-design-system/pds/latest/fonts/worksans/OFL.txt
  * Coded @ Principal with love
  */
select,select option{color:#333}select{font-family:"FS Elliot Web Regular",Arial,arial,sans-serif;border:1px solid #616267;font-size:16px;box-shadow:none;display:block;width:100%;height:48px;padding-left:12px;padding-right:36px;background-color:#fff;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-image:url("data:image/svg+xml,%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23333333' d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E");background-repeat:no-repeat,repeat;background-position:right 8px top 50%,0 0;background-size:1.75rem auto,100%;border-radius:8px}select.pds-select-small{height:40px}select:active,select:focus{outline:0;border-color:#035fa4;box-shadow:0 0 8px #035fa4}select:active,select:focus,select:hover{background-color:#fbfbfb}select.pds-select-error{border-color:#c00000}select.pds-select-error:active,select.pds-select-error:focus{border:1px solid #0091da}select:disabled{color:#c5c6c5;background-color:#f6f6f8;opacity:1;cursor:not-allowed;border:1px solid #c5c6c5;background-image:url("data:image/svg+xml,%3Csvg width='1792' height='1792' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23c5c6c5' d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E");box-shadow:none}select [hidden],select [placeholder],select:invalid{color:#e8e8e8}select[multiple]{height:auto;background-image:none;padding:12px}select option:checked{font-family:"FS Elliot Web Bold","FS Elliot Web Regular",Arial,arial,sans-serif}select::-ms-expand{display:none}