/*!
  * Principal Design System
  * Component: radio
  * Version: 2.8.0
  * Build Date: 4/29/2021, 3:56:32 PM
  * Repo: https://scm.principal.com/projects/GCBIDESIGN/repos/principal-design-system/browse
  * Crimson Text Font licensing: https://www.principalcdn.com/css/principal-design-system/pds/latest/fonts/crimson/OFL.txt
  * Work Sans Font licensing: https://www.principalcdn.com/css/principal-design-system/pds/latest/fonts/worksans/OFL.txt
  * Coded @ Principal with love
  */
.pds-radio>input{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border:0}.pds-radio>input:focus+label:before{box-shadow:0 0 5px #034170}.pds-radio>input+label{position:relative;cursor:pointer;padding:2px 1px 1px 32px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#333;font-family:"FS Elliot Web Regular",Arial,arial,sans-serif;font-size:16px}.pds-radio>input+label:before{content:'';position:absolute;top:1.5px;left:1.5px;border:1px solid #616267;display:block;background-color:#fff;transition:background-color .1s cubic-bezier(.4,0,.2,1)}.pds-radio>input:hover+label:before{background-color:#fbfbfb;border:1px solid #04508A}.pds-radio>input:active+label:before{background-color:#fbfbfb;border:1px solid #034170}.pds-radio>input:disabled+label{cursor:not-allowed;color:#9d9ea4}.pds-radio>input+label:after{position:absolute;top:4.5px;left:4.5px;height:14px;width:14px}.pds-radio>input+label:before{border-radius:50%;width:20px;height:20px}.pds-radio>input:checked+label:after{content:'';background-color:#034170;border-radius:50%;-webkit-animation:radio-pop .14s;animation:radio-pop .14s}.pds-radio>input:disabled+label:before{background-color:#f6f6f8;border-color:#c5c6c5}.pds-radio>input:checked:disabled+label:after{background-color:#c5c6c5}@-webkit-keyframes radio-pop{0%{opacity:0;transform:scale(0)}100%{opacity:1;transform:scale(1.2)}}@keyframes radio-pop{0%{opacity:0;transform:scale(0)}100%{opacity:1;transform:scale(1.2)}}